html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0a0a0a;
  color: #fff;
}

body {
  /*font-family: 'Montserrat', sans-serif;
  font-family: 'Nunito Sans', sans-serif;*/
  font-family:Source Sans Pro, sans-serif;
  font-weight:300;
  font-size:1rem;
  line-height:1.65;
}

#root {
  min-height: 100vh;
  /*background-image: url(../public/background.jpg);*/
  background-image: linear-gradient(0deg,rgba(19,21,25,.5),rgba(19,21,25,.5)),url(../public/background.jpg);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
}

.heroContainer {
  text-align: center;
}

.heroText {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 2rem;
  text-transform: uppercase;
}

.buttonAbout {
  padding-top: 30px;
}

.aboutModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 80%;
  background-color: #262222;
  border: 1px solid #777;
  /*box-shadow: 3px 3px 3px #000;*/
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

img {
  max-width: 100%;
  max-height: 30vh;
}

/*.App {
  text-align: center;
  background-image: url(../public/logo512.png);
  min-height: 100vh;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}*/

/*.mainContent {
  justify-content: center;
}*/